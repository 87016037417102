import Layout from "../components/layout"
import React from "react"
import {
  Paragraph2,
  Part2,
  Paragraph1,
  Title,
  ContactContent,
  ContactWrapper,
} from "../styles/contact/style"

const Contact = () => {
  return (
    <Layout>
      <ContactWrapper>
        <ContactContent>
          <Title>Get in touch</Title>
          <Paragraph1>
            In today's global marketplace, solving business problems requires a
            global perspective paired with a local sensibility.{" "}
            <Part2>
              Whether you are looking for a design partner, strategic
              consultant, or looking to join our team, connect with us below.
            </Part2>
          </Paragraph1>
          <Paragraph2>+44 [0] 7768 012 364</Paragraph2>
          <Paragraph2>info@michaelallenpartners.com</Paragraph2>
        </ContactContent>
      </ContactWrapper>
    </Layout>
  )
}

export default Contact
